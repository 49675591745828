import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/apps/yoshimotokuji/src/app/(default)/page.module.scss");
import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/apps/yoshimotokuji/src/assets/images/status/status-label-end.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/apps/yoshimotokuji/src/assets/images/status/status-label-hold.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/apps/yoshimotokuji/src/assets/images/status/status-label-notice.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/apps/yoshimotokuji/src/assets/images/text/information.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/apps/yoshimotokuji/src/assets/images/text/line-up.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/apps/yoshimotokuji/src/components/form/LinkButton/LinkButton.module.scss");
import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/apps/yoshimotokuji/src/components/icon/FaIcon/FaIcon.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/apps/yoshimotokuji/src/components/ui/ImageView/ImageView.module.scss");
import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/apps/yoshimotokuji/src/components/ui/ScrollFade/ScrollFade.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/apps/yoshimotokuji/src/features/lottery/components/Keywords/Keywords.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/apps/yoshimotokuji/src/features/lottery/components/LotteryEventList/LotteryEventList.module.scss");
import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/apps/yoshimotokuji/src/features/lottery/components/LotterySlider/LotterySlider.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1858399199/src/node_modules/next/dist/client/link.js")